<template>
	<div class="charts position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative"
			 style="z-index:1000;">

			<div class="page-header">
				<h1 class="page-title" v-text="this.title" />
				<div class="d-flex position-relative">
					<div class="d-flex w-100 text-nowrap-mask scroller scroller-x position-sticky">
						<NavTabs :navItems="this.navTabs"
								 @onClick="(target_id) => (this.resetFilter && this.applyFilter(this.filters))" />
					</div>
				</div>
				<div class="d-flex border border-brd-primary border-start-0 border-end-0 border-bottom-0 pt-3 pb-1">
					<div class="input-group">
						<button id="button-addon1"
								type="button"
								class="btn btn-outline-primary me-2 rounded-3 fw-semi font-semi"
								@click="this.isFilterOpened = !this.isFilterOpened">
							{{ this.filtersCountLabel }}
							<img :src="require('@/assets/icons/arr-sm-down-icon.svg')" class="ms-1 icon-color-primary" alt="Развернуть" width="12" height="12">
						</button>
						<input type="text" class="form-control border-0 px-1 me-2"
							   placeholder="Поиск"
							   aria-label="Поиск"
							   ref="searchInput"
							   @change="(event) => this.search_query = event.target.value.toString()" />
						<button v-if="this.show_reset_filter" id="button-addon3" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-2 me-0 border border-brd-primary rounded-1"
								aria-label="Очистить"
								@click="this.resetFilter()">
							<img :src="require('@/assets/icons/close-icon.svg')" alt="Очистить" width="14" height="14">
						</button>
						<button id="button-addon2" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-0 border border-brd-primary rounded-1"
								aria-label="Осуществить поиск"
								@click="this.doSearch()">
							<img :src="require('@/assets/icons/search-icon.svg')" alt="Поиск" width="18" height="18">
						</button>
					</div>
				</div>
			</div>

			<Filters :isOpen="this.isFilterOpened"
					 :section="this.section"
					 :filters="this.filters"
					 @closeFilter="data => this.closeFilter(data)"
					 @setupFilter="this.filterChange"
					 @applyFilter="this.applyFilter"/>

		</div>
		<div class="tab-content py-20">
            <div id="dashboard" class="tab-pane fade show active"
				 role="tabpanel"
				 aria-labelledby="Рабочий стол">
				<div class="px-20">
					<StatList :data="this.stat_data" />
				</div>
				<div class="px-20">
					<SalesData :data="this.sales_data"/>
				</div>
            </div>
            <div id="tasks" class="tab-pane fade position-relative"
				 role="tabpanel"
				 aria-labelledby="Задачи">
				<button 
					class="add-btn btn d-flex align-items-center justify-content-center rounded-pill position-absolute bg-primary rounded-circle"
				>
					<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
				</button>
				<CalendarComponent />
            </div>
            <div id="stats" class="tab-pane fade"
				 role="tabpanel"
				 aria-labelledby="Статистика">
				<div class="d-flex flex-column p-10 p-sm-20 pt-0 pt-sm-0">

					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0 mb-20">
						<FunnelChart :data="funnelChartData" :title="'Воронка продаж'"/>
					</div>
					
					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0 mb-20">
						<PieChart :data="pieChartData" :title="'Клиенты по рекламным источникам/партнёрам'"/>
					</div>

					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0">
						<div class="border-bottom border-brd-primary p-20">
							<span class="fs-2">Сумма сделок</span>
						</div>

						<div class="p-20 mt-auto">
							<span class="fs-1">900 595 p</span>
						</div>
					</div>
				</div>
            </div>
            <div id="stats_objects" class="tab-pane fade"
				 role="tabpanel"
				 aria-labelledby="Статистика объекты">
				<div class="d-flex flex-column p-10 p-sm-20 pt-0 pt-sm-0">

					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0 mb-20">
						<FunnelChart :data="funnelChartData" :title="'Воронка продаж'"/>
					</div>

					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0 mb-20">
						<PieChart :data="pieChartData" :title="'Клиенты по рекламным источникам/партнёрам'"/>
					</div>

					<div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid p-0">
						<div class="border-bottom border-brd-primary p-20">
							<span class="fs-2">Сумма сделок</span>
						</div>

						<div class="p-20 mt-auto">
							<span class="fs-1">900 595 p</span>
						</div>
					</div>
				</div>
            </div>
        </div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";
    import NavTabs from '@/components/common/NavTabs'
	import StatList from '@/components/charts/StatList'
	import Filters from '@/components/common/Filters.vue'
	import PieChart from '@/components/charts/PieChart'
	import FunnelChart from '@/components/charts/FunnelChart'
	
	import SalesData from '@/components/charts/SalesData'
	import CalendarComponent from "@/components/common/CalendarComponent"
	import api from "@/api";

    export default {
        name: "charts",

        data() {
            return {
                title: "Кабинет",
				isFilterOpened: false,
				filters: {},
				section: 'charts',
				filtersCount: 0,
				filtersCountLabel: 'Фильтр',
				search_query: '',
				navTabs: {
					'dashboard': {
						id: 'dashboard-tab',
						label: "Рабочий стол",
						title: "Рабочий стол",
						count: 0,
						active: true,
						data_bs_toggle:'tab',
						data_bs_target: '#dashboard',
						href: '#',
						action: () => console.log('NavTab click!')
					},
					'tasks': {
						id: 'tasks-tab',
						label: "Задачи",
						title: "Задачи",
						count: 0,
						active: false,
						data_bs_toggle:'tab',
						data_bs_target: '#tasks',
						href: '#',
						action: () => console.log('NavTab click!')
					},
					'stats': {
						id: 'stats-tab',
						label: "Статистика",
						title: "Статистика",
						count: 0,
						active: false,
						data_bs_toggle:'tab',
						data_bs_target: '#stats',
						href: '#',
						action: () => console.log('NavTab click!')
					},
					'stats_objects': {
						id: 'stats_objects-tab',
						label: "Статистика объекты",
						title: "Статистика объекты",
						count: 0,
						active: false,
						data_bs_toggle:'tab',
						data_bs_target: '#stats_objects',
						href: '#',
						action: () => console.log('NavTab click!')
					},
				},
				
				funnelChartData: [
                    {
                        "name": "Новые клиенты",
                        "value": 17,
                    }, 
                    {
                        "name": "В работе",
                        "value": 25
                    }, 
                    {
                        "name": "Завершенные клиенты",
                        "value": 37
                    }
                ],

				pieChartData: [
					{
						"title": "Avito",
						"value": 11
                    }, 
                    
                    {
						"title": "Cian",
						"value": 2
                    }, 
                    
                    {
						"title": "FaceBook",
						"value": 13
                    }, 
                    
                    {
						"title": "JivoSite",
						"value": 16
                    }, 
                    
                    {
                    	"title": "Инстаграм",
                    	"value": 9
                    },
				],

				sales_data: {
					target: 0,
					current: 0,
					period: '-'
				},

				stat_data: {
					clients: {
						in_work: {
							id: 'in_work',
							title: 'Клиентов в работе:',
							value: '-',
							color: '#4CAF50'
						},

						opened: {
							id: 'opened',
							title: 'Открытые задачи по клиентам:',
							value: '-',
							color: '#8BC28A'
						},

						expired_tasks: {
							id: 'expired_tasks',
							title: 'Задач просрочено по клиентам:',
							value: '-',
							color: '#757579',
						},

						expired_clients: {
							id: 'expired_clients',
							title: 'Клиентов с просроченными этапами:',
							value: '-',
							color: '#757579',
						},

						success: {
							id: 'success',
							title: 'Успешные клиенты:',
							value: '-',
							color: '#9ED88A'
						},

						closed: {
							id: 'closed',
							title: 'Закрытые клиенты:',
							value: '-',
							color: '#CECECE'
						},
					},
					requisitions: {
						in_work: {
							id: 'in_work',
							title: 'Заявок в работе:',
							value: '-',
							color: '#29B1DC'
						},

						opened: {
							id: 'opened',
							title: 'Открытые задачи по заявкам:',
							value: '-',
							color: '#B2C4DF'
						},

						expired_tasks: {
							id: 'expired_tasks',
							title: 'Задач просрочено по заявкам:',
							value: '-',
							color: '#757579',
						},

						expired_req: {
							id: 'expired_req',
							title: 'Заявок с просроченными этапами:',
							value: '-',
							color: '#757579',
						},

						success: {
							id: 'success',
							title: 'Успешные заявки:',
							value: '-',
							color: '#4DD4FF'
						},

						closed: {
							id: 'closed',
							title: 'Закрытые заявки:',
							value: '-',
							color: '#CECECE'
						},
					},
					objects: {
						total: {
							id: 'total',
							title: 'Объектов всего:',
							value: '-',
							color: '#C26DB2',
						},

						in_adverts: {
							id: 'in_adverts',
							title: 'Объекты в рекламе:',
							value: '-',
							color: '#D198C6',
						},

						opened_tasks: {
							id: 'opened_tasks',
							title: 'Открытые задачи по объектам:',
							value: '-',
							color: '#F472DC'
						},

						expired_tasks: {
							id: 'expired_tasks',
							title: 'Задач просрочено по объектам:',
							value: '-',
							color: '#757579'
						},
					},
				},

            }
        },

		components: {
			CalendarComponent,
			NavTabs,
			StatList,
			Filters,
			PieChart,
			FunnelChart,
			SalesData
		},

		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			doSearch() {
				let query = this.search_query;
				if (!CommonService.isEmpty(query)) {
					CommonService.log('log', 'doSearch()', query);
					this.getStats(this.filters, this.search_query);
				}
			},
			getStats(filters, search_query) {
				return api.get('/common/stats', {
					params: {
						filters: filters || null,
						query: search_query || null
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getStats()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						
						this.stat_data.clients.in_work.value = response.data.clients.in_work;
						this.stat_data.clients.opened.value = response.data.clients.opened;
						this.stat_data.clients.expired_tasks.value = response.data.clients.expired_tasks;
						this.stat_data.clients.expired_clients.value = response.data.clients.expired_clients;
						this.stat_data.clients.success.value = response.data.clients.success;
						this.stat_data.clients.closed.value = response.data.clients.closed;

						this.stat_data.requisitions.in_work.value = response.data.requisitions.in_work;
						this.stat_data.requisitions.opened.value = response.data.requisitions.opened;
						this.stat_data.requisitions.expired_tasks.value = response.data.requisitions.expired_tasks;
						this.stat_data.requisitions.expired_req.value = response.data.requisitions.expired_req;
						this.stat_data.requisitions.success.value = response.data.requisitions.success;
						this.stat_data.requisitions.closed.value = response.data.requisitions.closed;

						this.stat_data.objects.total.value = response.data.objects.total;
						this.stat_data.objects.in_adverts.value = response.data.objects.in_adverts;
						this.stat_data.objects.opened_tasks.value = response.data.objects.opened_tasks;
						this.stat_data.objects.expired_tasks.value = response.data.objects.expired_tasks;

						this.sales_data.current = response.data.sales.current;
						this.sales_data.target = response.data.sales.target;
						this.sales_data.period = response.data.sales.period;

					}
					
					return this.stat_data;
				}).catch(function (error) {

					CommonService.log('error', 'getStats()::axios', error);

					return null;
				});
			},
			filterChange(filters) {
				this.filters = CommonService.removeEmpty(filters);
				CommonService.log('log', 'filterChange()', this.filters);
				this.filtersCount = Object.keys(this.filters).length;
				console.log('this.filtersCount', this.filtersCount);
            },
            applyFilter(filters) {
				this.isFilterOpened = false;
                console.log('applyFilter')
				this.getStats(this.filters);
            },
			resetFilter() {

				this.$refs.searchInput.value = null;
				if (Object.keys(this.filters).length > 0) {

					this.filters = {};
					this.filtersCount = 0;
					CommonService.log('log', 'resetFilter()', this.filters);

				}

				this.getStats(this.filters);
			},
			closeFilter(clear) {
				this.isFilterOpened = false;

				if (clear) {
					this.resetFilter();
					this.getStats(this.filters);
				}
			},
		},

		mounted() {
			CommonService.setPageTitle(this.title);

			if (this.filtersCount > 0)
				this.filtersCountLabel = CommonService.declOfNum(this.filtersCount, ['опция', 'опции', 'опций'], true);

			this.emitter.on('closeFilter', () => this.resetFilter());
			this.getStats(this.filters);
		},
		computed: {
			show_reset_filter() {
				return !this.isEmpty(this.search_query);
			},
		},
		watch: {
			filtersCount: function (value) {
				if (value > 0) {
					this.filtersCountLabel = CommonService.declOfNum(value, ['опция', 'опции', 'опций'], true);
				} else {
					this.filtersCountLabel = 'Фильтр';
				}
			}
		}
    }
</script>

<style lang="scss">
	.add-btn {
		right: 20px; 
		top: 12px; 
		width: 50px; 
		height: 50px;

		@media(max-width: 480px) {
			top: 7px;
		}
	}

	.legend-item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.legend-title {
		margin-right: auto;
	}

	.legend-marker {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 20px;
	}

	.progress-bar {
		background: linear-gradient(270deg, #4CAF50 0%, rgba(76, 175, 80, 0.5) 100%);
	}

</style>