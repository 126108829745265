<template>
    <div>
        <div class="d-flex flex-column bg-white rounded-3 border border-brd-primary row-fluid" >
            <div class="border-bottom border-brd-primary p-20">
                <span class="fs-2">План продаж</span> 
            </div>

            <div class="p-20">
                <div class="d-flex align-items-center justify-content-between mb-20 pb-20 row">
                    <div class="d-flex flex-column col-6">
                        <span class="fs-4 text-font-secondary mb-3">Цель</span>
                        <span class="fs-3">{{this.salesData.target}} р</span>
                    </div>
        
                    <div class="d-flex flex-column col-6">
                        <span class="fs-4 text-font-secondary mb-3">Период</span>
                        <span class="fs-3">{{this.salesData.period}}</span>
                    </div>
                </div>
        
                <div class="mb-20">
                    <div class="progress rounded-pill mb-20" style="height: 20px;">
                        <div class="progress-bar"
							 role="progressbar"
							 :style="'width:' +  this.salesData.currentRange + '%;'"
							 :aria-valuenow="salesData.currentRange"
							 aria-valuemin="0"
							 aria-valuemax="100">
                            <span clas="fs-5 fw-semi font-semi"
								  v-text="(!isNaN(this.salesData.currentRange) && !this.isEmpty(this.salesData.currentRange)) ? this.salesData.currentRange + '%': '0%'" />
                        </div>
                    </div>
        
                    <div class="position-relative">
                        <div 
                            class="timeline-body mb-20 position-relative" 
                            :style="`background-image: url(${require('@/assets/img/bg.svg')})`"
                        >
                            <span class="fs-5 text-font-secondary position-absolute start-0" style="bottom: -20px;">1 января</span>
                            <span class="fs-5 text-font-secondary position-absolute end-0"   style="bottom: -20px;">31 декабря</span>
                        </div>
        
                        <div class="timeline-pin d-flex flex-column align-items-center p-10" :style="'left: calc(' + this.daysData.range + '% - 30px' ">
                            <span class="fs-5 font-semi fw-semi mb-1">Сегодня</span>
                            <span class="fs-5 text-font-secondary">{{ this.daysData.day }}</span>
                            <span class="fs-5 text-font-secondary">{{ this.daysData.month }}</span>
                        </div>
        
                        <div class="timeline-marker"
							 :style="'left:' + this.daysData.range + '%'"
                        ></div>
                    </div>
                </div>
        
                <div class="d-flex flex-column pt-20">
                    <div class="sales-data-item d-flex flex-column bg-light-gray border-start border-primary py-3 px-20 mb-10">
                        <span class="fs-4 text-font-secondary mb-2">Выполнено</span>
                        <span class="fs-3">{{this.salesData.current}} ₽</span>
                    </div>
        
                    <div class="sales-data-item d-flex flex-column bg-light-gray border-start border-warning py-3 px-20 mb-10">
                        <span class="fs-4 text-font-secondary mb-2">Осталось</span>
                        <span class="fs-3">{{this.salesData.left}} ₽</span>
                    </div>
        
                    <div class="sales-data-item d-flex flex-column bg-light-gray border-start border-dark-gray py-3 px-20 mb-10">
                        <span class="fs-4 text-font-secondary mb-2">Процент выполнения</span>
                        <span class="fs-3" v-text="(!isNaN(this.salesData.currentRange) && !this.isEmpty(this.salesData.currentRange)) ? this.salesData.currentRange + '%': '0%'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonService from "@/services/CommonService";

	export default {
        name: 'SalesDataChart',

        props: {
            data: { type: Object}
        },

        data() {
            return {
                salesData: {
                    currentRange: null,
                    target: null,
                    current: null,
                    left: null,
                    period: null
                },

                daysData: {
                    day: null,
                    month: null,
                    range: null,
                    current: null,
                    count: null,
                }
            }
        },

        methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
            initTimeline() {
                let currentYear = new Date().getYear
                let isLeap = new Date(currentYear , 1, 29).getMonth() == 1
                isLeap ? this.daysData.count = 364 : this.daysData.count = 365

                let now = new Date();
                let start = new Date(now.getFullYear(), 0, 0);
                var oneDay = 1000 * 60 * 60 * 24;
                var day = Math.floor( (now - start) / oneDay );

                this.daysData.day = new Date().getDate();
                let month = new Date().getMonth() + 1;

                this.daysData.month = 
                    month === 1 ? 'Января' :
                    month === 2 ? 'Февраля' :
                    month === 3 ? 'Марта' :
                    month === 4 ? 'Апреля' :
                    month === 5 ? 'Май' :
                    month === 6 ? 'Июня' :
                    month === 7 ? 'Июля' :
                    month === 8 ? 'Августа' :
                    month === 9 ? 'Сентября' :
                    month === 10 ? 'Октября' :
                    month === 11 ? 'Ноября' : 'Декабря'

                this.daysData.current = day;
                this.daysData.range = ( this.daysData.current / (this.daysData.count / 100));
            },

            initProgressBar() {
                this.salesData.currentRange = (this.$props.data.current / (this.$props.data.target / 100)).toFixed(2)
                this.salesData.target = this.$props.data.target
                this.salesData.current = this.$props.data.current
                this.salesData.period = this.$props.data.period
                this.salesData.left = this.$props.data.target - this.$props.data.current
            },

            initData() {
                this.initTimeline()
                this.initProgressBar()
            }
        },

        mounted() {
            this.initData()
        }
    }
</script>

<style lang="scss">
    .sales-data-item {
        border-radius: 2px;
        border-width: 3px !important;
    }

    .timeline-body {
        position: relative;
        width: 100%;
        height: 20px;
        background-repeat: repeat;
        background-size: calc(100% / 12);
    }

    .timeline-marker {
        position: absolute;
        top: 0;
        left: 50%;
        width: 3px;
        height: 50%;
        content: '';
        background-color: #EE6161;
    }

    .timeline-pin {
        opacity: 0;
        position: absolute;
        top: -63px;
        left: 50%;
        width: 65px;
        height: 60px;
        content: '';
        border-radius: 12px;
        background-color: #ffffff;
        filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
        transition: opacity 0.3s linear;      
    }

    .timeline-body:hover + .timeline-pin {
        opacity: 1;
    }
</style>