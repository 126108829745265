<template>
    <div>
        <div class="border-bottom border-brd-primary p-10 p-sm-20">
            <span class="fs-2">{{ this.$props.title }}</span>
        </div>
        <div class="p-2 p-sm-20">
            <div ref="chartdiv" style="min-height: 260px; max-width: 400px; margin: 0 auto;" class="mb-20"></div>
            <div id="legend"></div>
        </div>
        <div class="border-top border-brd-primary p-10 p-sm-20">
            <span>Всего: {{ this.totalCount }}</span>
        </div>
    </div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
	import * as am4charts from "@amcharts/amcharts4/charts";
	import am4themes_animated from "@amcharts/amcharts4/themes/animated";

	am4core.useTheme(am4themes_animated);

    export default {
        name: 'PieChart',

        props: { data: { type: Array }, title: { type: String }},

        data() {
            return {
                totalCount: null
            }
        },

        methods: {
            initPieChart() {
                am4core.useTheme(am4themes_animated);

                // Create chart instance
                var chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

                // Let's cut a hole in our Pie chart the size of 40% the radius
                chart.innerRadius = am4core.percent(0);

                // Add data
                chart.data = chart.data = this.$props.data

                // Add and configure Series
                var pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "value";
                pieSeries.dataFields.category = "title";
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;

                // Disabling labels and ticks on inner circle
                pieSeries.labels.template.disabled = true;
                pieSeries.ticks.template.disabled = true;

                // Disable sliding out of slices
                pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
                pieSeries.slices.template.states.getKey("hover").properties.scale = 1.1;
                
                chart.data.forEach(item => this.totalCount += item.value)

                chart.colors.list = [
                    am4core.color("#EF7E76"),
                    am4core.color("#8EB3F5"),
                    am4core.color("#81ADBB"),
                    am4core.color("#2E7233"),
                    am4core.color("#48964C"),
                    am4core.color("#61B965"),
                    am4core.color("#C8E6C9"),
                    am4core.color("#E8F5E9"),
                    am4core.color("#2CD873"),
                    am4core.color("#DDDDDD"),
                    am4core.color("#999999"),
                    am4core.color("#333333"),
                    am4core.color("#97F277"),
                    am4core.color("#BCBD63"),
                    am4core.color("#74E3C7"),
                ];

                pieSeries.colors.list = [
                    am4core.color("#EF7E76"),
                    am4core.color("#8EB3F5"),
                    am4core.color("#81ADBB"),
                    am4core.color("#2E7233"),
                    am4core.color("#48964C"),
                    am4core.color("#61B965"),
                    am4core.color("#C8E6C9"),
                    am4core.color("#E8F5E9"),
                    am4core.color("#2CD873"),
                    am4core.color("#DDDDDD"),
                    am4core.color("#999999"),
                    am4core.color("#333333"),
                    am4core.color("#97F277"),
                    am4core.color("#BCBD63"),
                    am4core.color("#74E3C7"),
                ];              

                pieSeries.dataFields.value = "value";
                pieSeries.dataFields.category = "title";
                pieSeries.labels.template.disabled = true;
                pieSeries.slices.template.stroke = "#ffffff";
                pieSeries.slices.template.strokeWidth = 2;

                // Disable pulling out
                pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;

                // Set up slices
                pieSeries.slices.template.tooltipText = "";
                pieSeries.slices.template.alwaysShowTooltip = true;

                // Add slice click event
                let currentSlice = null;

                pieSeries.slices.template.events.on("hit", function(ev) {
                    if (currentSlice) {
                        currentSlice.tooltip.hide();
                    }
                    currentSlice = ev.target;
                    currentSlice.tooltipText = "{category}: {value.percent.formatNumber('#.#')}% ({value.value})";
                    currentSlice.invalidate();
                    currentSlice.showTooltip();
                });

                pieSeries.slices.template.events.on("over", function(ev) {
                    if (currentSlice) {
                        currentSlice.tooltip.hide();
                    }
                    currentSlice = ev.target;
                    currentSlice.tooltipText = "{category}: {value.percent.formatNumber('#.#')}% ({value.value})";
                    currentSlice.invalidate();
                    currentSlice.showTooltip();
                });

                pieSeries.slices.template.events.on("out", function(ev) {
                    currentSlice.tooltip.hide();
                    currentSlice.tooltipText = "";
                });

                // Set up page click event to close open tooltip
                am4core.getInteraction().body.events.on("hit", function(ev) {
                    if (currentSlice) {
                        currentSlice.tooltip.hide();
                    }
                });

                // Create custom legend
				chart.events.on("ready", function(event) {
					chart.customLegend = document.getElementById('legend');

					pieSeries.dataItems.each((row, i) => {
						let slice = pieSeries.dataItems.getIndex(i);
						let color = chart.colors.getIndex(i);
						let value = row.value;

						let item = document.createElement("div");
						item.innerHTML = '<div class="legend-item" id="legend-item-' + i + '"><div class="legend-marker" style="background: ' + color + '"></div><span class="legend-title">' + row.category + '</span><div class="legend-value">' + value + '</div></div>';
						item.onclick = () => toggleSlice(item, value, slice);
                        /*item.onmouseover = () => hoverSlice(i, slice);
                        item.onmouseout = () => blurSlice(i, slice);*/

						chart.customLegend.appendChild(item);
					});
				});

                const toggleSlice = (item, value, slice) => {

                    /* pieSeries.slices.values.forEach(item => {
                        item.tooltipText = ""
                    }) */

                    if (slice.visible) {
                        slice.hide();
                        if(!item.classList.contains('item-disabled')) {
                            this.totalCount -= value
                        }
                        item.classList.add('item-disabled');
                    }
                    else {
                        slice.show();
                        
                        if(item.classList.contains('item-disabled')) {
                            this.totalCount += value
                        }
                        item.classList.remove('item-disabled');
                    }
                }

                /*function hoverSlice(i, slice) {
                    slice.isHover = true;
                }

                function blurSlice(i, slice) {
                    slice.isHover = false;
                } */
            }
        },

        mounted() {
            this.initPieChart()
		},

		beforeUnmount() {
			if (this.chart) {
				this.chart.dispose();
			}
		}
    }
</script>

<style>
    .item-disabled {
        opacity: 0.5;
    }
</style>