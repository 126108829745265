<template>
    <div>
        <div 
            class="bg-white rounded-3 border border-brd-primary container-fluid p-0 mb-20"
            v-for="(data,i) of this.$props.data"
            :key="'data_' + i"
        >
            <ul
                class="d-flex flex-wrap row m-0 p-0"
            >
                <li v-for="(dataItem, i) of data"
                    :key="'dataItem_' + i"
                    class="stat-item d-flex align-items-center col-6 p-10 p-sm-20 border-brd-primary"
                    :class="i !== data.length - 1 && i !==data.length - 2 ? 'border-bottom' : null"
                    style="min-height: 100px;">
                    <span 
                        class="fs-4 font-semi fw-semi text-font-secondary me-auto pe-20"
                        
                        style="max-width: 130px;"
                    >{{dataItem.title}}</span>

                    <span class="fs-2 font-semi fw-semi" :style="'color:' + dataItem.color ">{{dataItem.value}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatList',

        props: {
            data: { type: Object}
        }
    }
</script>

<style lang="scss">
    .stat-item {
        &:nth-child(odd) {
            border-right: 1px solid #dedede;
        }

        @media(max-width: 480px) {
            min-height: 70px !important;

            span:first-child {
                font-size: 14px !important;
                padding-right: 10px !important;
            }

            span:last-child {
                font-size: 16px !important;
            }
        }
    }
</style>